import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const PrivacyPolicy = () => (
  <Layout>
    <Seo title="Privacy Policy" />
    <div className="container py-5 text-white">
      <h1 className="h3 bank-font text-center">Privacy Policy</h1>
      <div className="row justify-content-center">
        <div className="col-8">
          <section className="mt-4">
            <p className="minimal-body">
              This Privacy Policy describes how your personal information is
              collected, used, and shared when you visit or make a purchase from
              www.tawalap.com (the “Site”).
            </p>
          </section>

          <section className="mt-4">
            <p className="minimal-title">PERSONAL INFORMATION WE COLLECT</p>
            <p className="minimal-body">
              When you visit the Site, we automatically collect certain
              information about your device, including information about your
              web browser, IP address, time zone, and some of the cookies that
              are installed on your device. Additionally, as you browse the
              Site, we collect information about the individual web pages or
              products that you view, what websites or search terms referred you
              to the Site, and information about how you interact with the Site.
              We refer to this automatically-collected information as “Device
              Information.”
            </p>
          </section>
          <section className="mt-4">
            <p className="minimal-body">
              We collect Device Information using the following technologies:
            </p>
            <p className="minimal-body">
              “Cookies” are data files that are placed on your device or
              computer and often include an anonymous unique identifier. For
              more information about cookies, and how to disable cookies, visit
              http://www.allaboutcookies.org.
            </p>
            <p className="minimal-body">
              Additionally when you make a purchase or attempt to make a
              purchase through the Site, we collect certain information from
              you, including your name, billing address, shipping address,
              payment information (including credit card numbers), email
              address, and phone number. We refer to this information as “Order
              Information.”
            </p>
            <p className="minimal-body">
              When we talk about “Personal Information” in this Privacy Policy,
              we are talking both about Device Information and Order
              Information.
            </p>
          </section>
          <section className="mt-4">
            <p className="minimal-title">
              HOW DO WE USE YOUR PERSONAL INFORMATION?
            </p>
            <p className="minimal-body">
              We use the Order Information that we collect generally to fulfill
              any orders placed through the Site (including processing your
              payment information, arranging for shipping, and providing you
              with invoices and/or order confirmations). Additionally, we use
              this Order Information to:
            </p>
            <p className="minimal-body">Communicate with you;</p>
            <p className="minimal-body">
              We use the Device Information that we collect to help us screen
              for potential risk and fraud (in particular, your IP address), and
              more generally to improve and optimize our Site (for example, by
              generating analytics about how our customers browse and interact
              with the Site, and to assess the success of our marketing and
              advertising campaigns).
            </p>
          </section>
          <section className="mt-4">
            <p className="minimal-title">SHARING YOUR PERSONAL INFORMATION</p>
            <p className="minimal-body">
              We share your Personal Information with third parties to help us
              use your Personal Information, as described above. For example, we
              use Stripe and PayPal to power our online store. We also use
              Hotjar for Analytics to help us anonymously understand how our
              customers use the Site--you can disable this by using browser
              extentions.
            </p>
            <p className="minimal-body">
              Finally, we may also share your Personal Information to comply
              with applicable laws and regulations, to respond to a subpoena,
              search warrant or other lawful request for information we receive,
              or to otherwise protect our rights.
            </p>
          </section>
          <section className="mt-4">
            <p className="minimal-title">YOUR RIGHTS</p>
            <p className="minimal-body">
              If you are a European resident, you have the right to access
              personal information we hold about you and to ask that your
              personal information be corrected, updated, or deleted. If you
              would like to exercise this right, please contact us through the
              contact information below.
            </p>
            <p className="minimal-body">
              Additionally, if you are a European resident we note that we are
              processing your information in order to fulfill contracts we might
              have with you (for example if you make an order through the Site),
              or otherwise to pursue our legitimate business interests listed
              above. Additionally, please note that your information will be
              transferred outside of Europe, including to Canada and the United
              States.
            </p>
          </section>
          <section className="mt-4">
            <p className="minimal-title">DATA RETENTION</p>
            <p className="minimal-body">
              When you place an order through the Site, we will maintain your
              Order Information for our records unless and until you ask us to
              delete this information.
            </p>
          </section>
          <section className="mt-4">
            <p className="minimal-title">CHANGES</p>
            <p className="minimal-body">
              We may update this privacy policy from time to time in order to
              reflect, for example, changes to our practices or for other
              operational, legal or regulatory reasons.
            </p>
          </section>
          <section className="mt-4">
            <p className="minimal-title">CONTACT US</p>
            <p className="minimal-body">
              For more information about our privacy practices, if you have
              questions, please contact us by e-mail at shlama@tawalap.com
            </p>
          </section>
        </div>
      </div>
    </div>
  </Layout>
)

export default PrivacyPolicy
